<template>
  <section class="faq">
    <div class="container">
      <h2 class="section-title">{{
          $vuetify.lang.t("$vuetify.view.forPsychologists.FAQ")
        }}</h2>
      <div class="mt-16">
        <v-expansion-panels
          v-model="panel"
          multiple
        >
          <v-expansion-panel
            v-for="faq in faqList"
            :key="faq.id"
            @click="openModal(faq)"
          >
            <v-expansion-panel-header class="faq__item-title list-title" expand-icon="mdi-plus"> {{ faq.title }}</v-expansion-panel-header>
            <v-expansion-panel-content class="faq__item-description pb-4" v-if="typeof(faq.description) === 'string'">{{ faq.description }}</v-expansion-panel-content>
            <v-expansion-panel-content class="faq__item-description" v-else-if="typeof(faq.description) === 'object'" e v-for="(item, i) in faq.description" :key="i">{{ item }}</v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div class="d-flex justify-center">
        <a class="faq__btn"  :href="linkToDashboard" target="_blank">
          <button class="main-btn">{{
              $vuetify.lang.t("$vuetify.view.forPsychologists.registrationButton1")
            }}</button>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FaqSection',
  data () {
    return {
      panel: [],
      linkToDashboard: process.env.VUE_APP_DASHBOARD + '/psychologistRegForm'
    }
  },
  computed: {
    faqList () {
      return [
        {
          id: 1,
          title: this.$vuetify.lang.t('$vuetify.view.forPsychologists.question1'),
          description: {
            item: this.$vuetify.lang.t('$vuetify.view.forPsychologists.answer1')
          },
          isOpen: false
        },
        {
          id: 2,
          title: this.$vuetify.lang.t('$vuetify.view.forPsychologists.question2'),
          description: {
            item: this.$vuetify.lang.t('$vuetify.view.forPsychologists.answer2')
          },
          isOpen: false
        },
        {
          id: 3,
          title: this.$vuetify.lang.t('$vuetify.view.forPsychologists.question3'),
          description: {
            item1: this.$vuetify.lang.t('$vuetify.view.forPsychologists.answer3[0]'),
            item2: this.$vuetify.lang.t('$vuetify.view.forPsychologists.answer3[1]'),
            item3: this.$vuetify.lang.t('$vuetify.view.forPsychologists.answer3[2]'),
            item4: this.$vuetify.lang.t('$vuetify.view.forPsychologists.answer3[3]'),
            item5: this.$vuetify.lang.t('$vuetify.view.forPsychologists.answer3[4]')
          },
          isOpen: false
        },
        {
          id: 4,
          title: this.$vuetify.lang.t('$vuetify.view.forPsychologists.question4'),
          description: {
            item: this.$vuetify.lang.t('$vuetify.view.forPsychologists.answer4')
          },
          isOpen: false
        }
      ]
    }
  },
  methods: {
    openModal (item) {
      item.isOpen = !item.isOpen
    }
  }
}
</script>

<style lang="scss" scoped>
.list-title {
  color: $lightColor;
  font-size: 1.3rem;
  font-weight: 400;
};
.faq {
  padding-top: 60px;
  &__item-title {
    font-size: 1.3rem !important;
    font-weight: 300 !important;
    z-index: 10;
  };
  &__item-description {
    color: $darkTextColor;
    font-weight: 300 !important;
  };
  &__btn {
    margin: 100px 0;
    flex-basis:  320px;
    & button {
      width: 100%;
    }
  }
  &__item-icon {
    position: absolute;
    margin-top: -10px;
    margin-right: 20px;
    top: 0;
    right: 0;
    font-size: 50px;
    font-weight: 300;
    transition: 0.5s;
    color: $lightColor;
  }
}
</style>
